import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../components/Dropdown/Dropdown';
import PageLoader from '../../../components/pageLoader/pageLoader';
import formatNumber from '../../../utils/metricSuffix';
import './Insights.scss';
import {
	MDDimensionsList,
	MDMetricsList,
	SocialMediaMatrices,
} from './InsightsData';

import './Insights.scss';
import headerService from '../../../services/header.service';
import {
	setCmoDimensionValue,
	setCmoMetricValue,
} from '../../../actions/cmoDashboardActions';
import { useEffect, useState } from 'react';

function BarGraph(props: any) {
	// stack matlab ek bar graph ka danda ek categ represet karega
	// let dummyGraphData = [
	// 	{
	// 		"name": "Paid Search",
	// 		"data": [
	// 			24702.37,
	// 			19765.65,
	// 			11437.32,
	// 			268.75,
	// 			0,
	// 			0,
	// 			0
	// 		],
	// 		stack: "Jan",
	// 	},{
	// 		"name": "Paid Game",
	// 		"data": [
	// 			24702.37,
	// 			19765.65,
	// 			14339,
	// 			28.75,
	// 			0,
	// 			495,
	// 			0
	// 		],
	// 		stack: "feb",
	// 	},{
	// 		"name": "Merge Sort",
	// 		"data": [
	// 			24702.37,
	// 			45975.65,
	// 			14339,
	// 			28.75,
	// 			0,
	// 			495,
	// 			0
	// 		],
	// 		stack: "march",
	// 	}
	// ]
	// ------------------------- States ------------------------------
	const [isTrendsTableLoader, setIsTrendsTableLoader]: any = useState(false);
	// ---------------------------------------------------------------

	// ------------------------- Redux Management ---------------------
	const isPdfClicked = useSelector((state: any) => state?.isPdfClicked);
	const cmoDashboardStore: any = useSelector((state) => state);
	const reduxDispatch: any = useDispatch();

	// ----------------------------------------------------------------

	const eventFromService = (eventData: any) => {
		switch (eventData.type) {
			case 'trendsAndDataTableHit':
				setIsTrendsTableLoader(eventData.data);
				break;
		}
	};
	useEffect(() => {
		let headerSubscription = headerService.subscribe(eventFromService);
		return () => {
			headerSubscription.unsubscribe();
		};
	}, []);
	Highcharts.SVGRenderer.prototype.symbols['c-rect'] = function (
		x: any,
		y: any,
		w: any,
		h: any
	) {
		return ['M', x, y + h / 2, 'L', x + w, y + h / 2];
	};
	const theme: any = useTheme();
	NoDataToDisplay(Highcharts);

	const findVal = (val: any) => {
		const arr = MDMetricsList;
		const check: any = arr.find((i: any) => i.value === val) || '';
		return check?.name?.toUpperCase();
	};

	const options = {
		chart: {
			type: 'column',
			// plotBackgroundColor: null,
			plotBorderWidth: null,
			plotBackgroundColor:
				theme.palette.mode === 'dark' && props.backgroundColor
					? props.backgroundColor
					: theme.palette.background.alt,
			backgroundColor:
				theme.palette.mode === 'dark' && props.backgroundColor
					? props.backgroundColor
					: theme.palette.background.alt,
			borderColor:
				theme.palette.mode === 'dark' && props.backgroundColor
					? props.backgroundColor
					: theme.palette.background.alt,
			plotShadow: false,
			className: 'highcharts-color-0',
			renderTo: 'chartContainer',
			height: props.height ? props.height : null,
		},
		title: {
			style: {
				fontSize: '22px',
				fontWeight: 'bold',
				fontFamily:
					"lato, BlinkMacSystemFont, 'Segoe UI', Loto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
			},
			text: props.title,
		},
		credits: {
			enabled: false,
		},

		xAxis: {
			visible: props.labels?.length ? true : false,
			labels: {
				enabled: props.labels?.length ? true : false,
				style: {
					color: theme.palette.text.primary,
				},
				// rotation: props.addRotation ? props.addRotation : 0,
			},
			className: 'highcharts-color-1',
			min: 0,
			max: props?.labels?.length ? props?.labels?.length - 1 : 11,
			categories: props.labels?.length
				? props.labels
				: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
				  ],
		},
		yAxis: {
			className: 'highcharts-color-0',
			min: 0,
			title: {
				text: props.addRotation
					? props.yAxisTitle
					: props.yAxisTitle
					? findVal(props.yAxisTitle)
					: null,
				style: {
					color: theme.palette.text.primary,
				},
			},
			y: -20,
			labels: {
				style: {
					color: theme.palette.text.primary,
				},
			},
			gridLineWidth: theme.palette.mode === 'dark' ? 0.2 : 1,
		},

		tooltip: {
			// @ts-ignore
			formatter: function () {
				// @ts-ignore

				return (
					// @ts-ignore
					props.addRotation
						? // @ts-ignore
						  '<b>' +
								// @ts-ignore
								this?.x +
								': ' +
								// @ts-ignore
								`${props.showHash ? '#' : '$'} ${this.y.toFixed(2)}` +
								'</b>'
						: props.yAxisTitle == 'revenue' ||
						  props.yAxisTitle == 'spend' ||
						  props.yAxisTitle == 'pipeline' ||
						  props.yAxisTitle == 'total_pipeline' ||
						  props.isTargetGraph
						? // @ts-ignore

						  `<span style="color:${this.series.color}">` +
						  // @ts-ignore
						  this?.series.name +
						  ': ' +
						  (props.fromInsights ? (props.showHash ? '#' : '$') : '') +
						  // @ts-ignore
						  formatNumber(this.y) +
						  '<br/>' +
						  '</b><br/>'
						: // @ts-ignore
						  `<span style="color:${this.series.color}">` +
						  // @ts-ignore
						  this?.series.name +
						  ': ' +
						  // @ts-ignore
						  formatNumber(this.y) +
						  '<br/>' +
						  '</b><br/>'
				);
				// @ts-ignore
			},
			// pointFormat: props.addRotation
			// 	? '<b>{point.y:.2f}</b>'
			// 	: props.yAxisTitle == 'revenue' ||
			// 	  props.yAxisTitle == 'spend' ||
			// 	  props.yAxisTitle == 'pipeline' ||
			// 	  props.isTargetGraph
			// 	? '<span style="color:{series.color}">{series.name}</span>:$<b>{point.y}</b><br/>'
			// 	: '<span style="color:{series.color}">{series.name}</span>:<b>{point.y}</b><br/>',
			// shared: props.isTargetGraph ? true : false,
			backgroundColor: 'white',
			borderColor: 'black',
			borderRadius: 10,
			borderWidth: 1,
			outside: true,
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				borderColor: null,
				borderWidth: 2,
				dataLabels: {
					enabled: false,
				},
				maxPointWidth: 20,
			},
			maxPointWidth: 36,
		},
		series: props.series || [],
		lang: {
			noData: props.noDataMessage || 'There is no data in the graph ',
		},
		noData: {
			style: {
				fontWeight: 'bold',
				fontSize: '15px',
				color: theme.palette.text.primary,
			},
		},

		colors: theme.palette.graph,

		legend: {
			itemDistance: 5,
			itemMarginTop: 5,
			itemMarginBottom: 5,
			itemWidth: 150,
			style: {
				margin: 'auto',
				textAlign: 'center',
			},
			itemStyle: {
				textAlign: 'right',
				fontSize: '13px',
				fontFamily: 'Lato !important',
				// color: '#707070',
				color: theme.palette.text.primary,
				fontWeight: '400 !important',
			},
			itemHoverStyle: {
				color: '#707070',
			},
			itemHiddenStyle: {
				color: '#707070',
			},
		},
	};
	return (
		<>
			<div
				className='stackedChart'
				style={{ backgroundColor: theme.palette.background.alt }}
			>
				{/* container for dropdowns above chart */}
				{!props.fromInsights && (
					<Grid
						container
						xs={12}
						display='flex'
						justifyContent='flex-end'
						alignItems='center'
						py='1rem'
						style={{
							backgroundColor: theme.palette.background.alt,
							display: isPdfClicked ? 'none' : '',
						}}
					>
						<Grid
							item
							className='stackedChart__dropdown'
							style={{ width: '150px' }}
						>
							<Dropdown
								valueSelector='value'
								itemList={[
									{ name: 'Revenue', value: 6 },
									{ name: 'Spend', value: 1 },
									{ name: 'Total Closed Won', value: 5 },
									{ name: 'Current Pipeline', value: 4 },
									{ name: 'Total Pipeline', value: 3 },
									{ name: 'Current Opportunity', value: 7 },
									{ name: 'Total Opportunities', value: 8 },
									{ name: 'Contacts', value: 9 },
									{ name: 'Leads', value: 2 },
									{ name: 'Sessions', value: 10 },
									{ name: 'Unique Visitors', value: 11 },
									{ name: 'Form Filled', value: 12 },
								]}
								label='name'
								name='Metrics'
								selectedValue={props.metricDropdown ? props.metricDropdown : 1}
								labelName='Metrics'
								onChange={(e: any) => {
									const data = {
										type: 'metricChange',
										data: e.target.value,
									};
									headerService.emit(data);
									// reduxDispatch(setCmoMetricValue(e.target.value));
									props.metricOnChange(e.target.value);
								}}
							/>
						</Grid>
						<Grid
							item
							className='stackedChart__dropdown'
							style={{ width: '150px' }}
						>
							<Dropdown
								valueSelector='value'
								itemList={MDDimensionsList}
								label='name'
								name='Dimensions'
								selectedValue={
									props.dimensionDropdown ? props.dimensionDropdown : 1
								}
								onChange={(e: any) => {
									const data = {
										type: 'dimensionChange',
										data: e.target.value,
									};
									headerService.emit(data);
									// reduxDispatch(setCmoDimensionValue(e.target.value));
									props.dimensionOnChange(e.target.value);
								}}
								labelName='Dimensions'
							/>
						</Grid>
					</Grid>
				)}
				{!isTrendsTableLoader ? (
					<div>
						<HighchartsReact
							// id="pieId"
							highcharts={Highcharts}
							options={options}
						/>
					</div>
				) : (
					<Grid
						item
						style={{
							width: '100%',
							height: '40vh',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress
							size={50}
							style={{ color: theme.palette.text.tertiary, zIndex: 9999 }}
						/>
					</Grid>
				)}
			</div>
		</>
	);
}
export default BarGraph;
